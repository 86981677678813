// TODO: Junaid - To review
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { convertTimeFormat, getProductionDateParts, filterDigitalDates } from 'utils/productions';
import { TP, VALIDATION_STATUS, PERFORMANCE_DATE_MODE_TYPES, ENTITY_TYPE, DATE_FORMATS } from 'constants/index';
import { getInitialsFromName } from 'utils';
import { getTicketsURL } from 'components/Productions/Display/TicketButton';
import { getMediaSource } from 'utils/media';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import SeparatorList from 'components/uiLibrary/SeparatorList';
import LinkButton from 'components/uiLibrary/LinkButton';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import { getCityCountry } from 'utils/globals';
import { COMPONENTS } from 'components/Globals/Analytics/constants';
import { getPerformanceLinkProps } from '../utils';

import classes from './ProductionComponents.module.scss';

export const DurationAndLangInfo = ({ production, size = 12, iconSize = 12, color = 'secondary' }) => {
  const subHeaderTags = useMemo(
    () =>
      [
        {
          icon: 'clock',
          value: production?.duration ? convertTimeFormat(production.duration) : '',
        },
        {
          icon: 'world',
          value: production?.languages?.map(subTitle => subTitle?.name).join(', '),
        },
        {
          icon: 'closed_caption',
          value: production?.subtitles?.map(subtitle => subtitle?.name).join(', '),
        },
      ].reduce((accum, tag) => {
        if (tag.value) {
          accum.push(
            <Typography className={classes.tag} size={size} color={color}>
              <SpriteIcon icon={tag.icon} size={iconSize} className={classes.icon} />
              {tag.value}
            </Typography>,
          );
        }
        return accum;
      }, []),
    [production],
  );

  return (
    <SeparatorList
      data={subHeaderTags}
      separator=""
      styles={{ root: classes.separatorListContainer, item: classes.separatorItem }}
    />
  );
};

export const ProductionStatus = ({ production, t, styles = {}, size = 12, color = 'secondary', italic = false }) => {
  const isRedMask = production?.validationStatus?.id === VALIDATION_STATUS.APPROVED;
  return (
    <Typography
      className={classnames(classes.redMask, styles.root, { [classes.italic]: italic })}
      color={color}
      size={size}
    >
      {isRedMask ? (
        <SpriteIcon icon="red_mask" className={classnames(classes.maskIcon, styles.maskIcon)} />
      ) : (
        <SpriteIcon icon="grey_mask" className={classnames(classes.maskIcon, styles.maskIcon)} />
      )}
      {isRedMask ? t(`${TP}.FN_PRODUCTION_STATUS_TEXT`) : t(`${TP}.FN_PRODUCTION_INFORMATION_NOT_VERIFIED`)}
    </Typography>
  );
};

export const ProductionWebsite = ({
  production,
  styles = {},
  t,
  size = 12,
  color = 'secondary',
  type,
  showRightIcon = false,
  trackingData,
}) => {
  const ticketsUrl = useMemo(() => getTicketsURL(production, { skipDateCheck: true }), [production]);
  const website = ticketsUrl || production?.website;

  return website ? (
    <LinkButton
      href={website}
      isLink
      variant="text"
      styles={styles.button}
      external
      rightIcon={showRightIcon ? <SpriteIcon icon="open_in_new" size={12} /> : undefined}
      trackingData={{
        ...trackingData,
        component: trackingData?.component || COMPONENTS.PRODUCTION_WEBSITE,
        meta: { website },
      }}
    >
      <Typography variant="span" size={size} color={color} className={styles.text} type={type}>
        {t(`${TP}.VISIT_WEBSITE`)}
      </Typography>
    </LinkButton>
  ) : null;
};

export const RenderEntityImage = ({ entity, size = 24, styles = {}, textSize = 12 }) => {
  const imageProps = {
    src: getMediaSource({ file: { urls: entity?.entity?.image } }, true),
    alt: entity?.entity?.name,
    width: size,
    transformations:
      entity?.entityType === ENTITY_TYPE.ARTIST ? TRANSFORMATIONS.PROFILE_THUMBNAIL : TRANSFORMATIONS.ORG_LOGO,
  };

  if (imageProps.src) {
    return (
      <div className={classnames(classes.entityImage, styles.root)}>
        <Image {...imageProps} disableNextImage />
      </div>
    );
  }
  return (
    <div className={classnames(classes.entityImage, styles.root)}>
      <Typography size={textSize} className={classnames(classes.imageLetters, styles.text)}>
        {getInitialsFromName(entity?.entity?.name)}
      </Typography>
    </div>
  );
};

const renderPerformanceType = char => (
  <Typography size={12} color="link" weight="medium" italic>
    {char}
  </Typography>
);

export const PerformanceDate = ({
  performance,
  isLast,
  navigate,
  production,
  isCancelled: isProductionCancelled,
  isArchived,
  trackingData,
}) => {
  const { linkProps, dateObj } = getPerformanceLinkProps({ performance, production, navigate });
  const isCancelledPerformance = isProductionCancelled || performance.isCancelled;
  return (
    <Typography
      variant="span"
      className={classnames({ [classes.isCancelled]: isCancelledPerformance })}
      isBlur={isArchived}
    >
      {' '}
      <LinkButton
        variant="text"
        target="_blank"
        isLink
        {...linkProps}
        trackingData={{ ...trackingData, meta: { ...trackingData?.meta, startDate: performance.startDate } }}
      >
        {performance.startDate && (
          <time dateTime={dateObj?.format(DATE_FORMATS.FULL_DATE)}>
            {performance.id !== -1 ? dateObj?.format(DATE_FORMATS.DATE) : performance.startTime}
          </time>
        )}
        {performance.isTour && renderPerformanceType('t')}
        {performance.isMatinee && renderPerformanceType('m')}
        {performance.mode === PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM && renderPerformanceType('ls')}
      </LinkButton>
      {!isLast && ','}
    </Typography>
  );
};

/**
 * Returns
 * "2024 25 Jun - 02 Jul (8 performances)"
 * "2023 - 2024 09 Nov to 24 May (16 performances)"
 */
export const ProductionDateSummary = ({ production, className, t }) => {
  // TODO: Translations everywhere in quick view
  // Date Range Separators
  const separators = {
    yearSeparator: ' - ',
    dateSeparator: ' to ',
    monthSeparator: ' - ',
  };
  const dateParts = useMemo(() => getProductionDateParts(production, { separators, shortMonth: true }), [production]);

  const performanceCount = filterDigitalDates(production)?.length || 0;

  return (
    <div className={classnames(classes.productionDateSummary, className)}>
      <Typography color="primary" weight="bold" className={classes.year}>
        {dateParts.years}
      </Typography>
      <Typography color="secondary" weight="medium" className={classes.dates}>
        {dateParts.dates}
      </Typography>
      <Typography size={12} color="secondary" className={classes.lowerCase}>
        ({performanceCount}{' '}
        {performanceCount === 1 ? t(`${TP}.FN_ADD_PRODUCTION_PERFORMACE`) : t(`${TP}.FN_PERFORMANCES`)})
      </Typography>
    </div>
  );
};

export const VenueAndStage = ({ performance, styles = {}, isArchived, t }) => {
  const { venue, venueStage } = performance;
  const venueName = venue?.name;
  const stageName = venueStage?.name;
  const cityCountry = getCityCountry(performance);

  if (!venueName && !cityCountry) {
    return (
      <Typography variant="p" size={12} className={classnames(styles.root, styles.venueNotAnnounced)} blur={isArchived}>
        {t(`${TP}.FN_VENUE_NOT_ANNOUNCED`)}
      </Typography>
    );
  }

  const venueOrStageExists = venueName || stageName;

  return (
    <Typography variant="p" size={12} className={styles.root} blur={isArchived}>
      {cityCountry}
      {venueOrStageExists && ' ('}
      {venueName}
      {venueName && stageName && ' : '}
      {stageName && (
        <Typography
          variant="p"
          size={12}
          className={styles.stageName}
          color="secondary"
          italic
          inline
          blur={isArchived}
        >
          {stageName}
        </Typography>
      )}
      {venueOrStageExists && ')'}
    </Typography>
  );
};
